<template>
  <div class="personal">
    <div v-if="$store.state.employeeInfo.userType ===4" class="enterprise">
      <div class="van-common-detail">
        <van-cell title="企业名称：" icon="newspaper-o" :value="employeeInfo.defaultOrganizationName" />
        <van-cell title="企业管理员：" icon="user-o" :value="employeeInfo.realName" />
      </div>
      <div class="contract-item">
        <van-cell title="我的额度" icon="after-sale" class="bigtxt" />
        <van-cell title="产品类型：" :value="utils.statusFormat(String(enterpriseInfo.productType), 'ProjectProductType')" />
        <van-cell title="合作额度(万元)：" :value="utils.moneyFormat(enterpriseInfo.quota)" />
        <van-cell title="合作期限(月)：" :value="utils.isEffectiveCommon(enterpriseInfo.term)" />
        <van-cell v-if="enterpriseInfo.quotaDateStart && enterpriseInfo.quotaDateEnd" title="额度起止日：" :value="utils.dateFormat(enterpriseInfo.quotaDateStart) + '至' + utils.dateFormat(enterpriseInfo.quotaDateEnd)" />
        <van-cell v-else title="额度起止日：" value="-" />
      </div>
    </div>
    <div v-else class="platform">
      <div class="van-common-detail">
        <van-cell title="用户名：" icon="user-circle-o" :value="employeeInfo.userName" />
        <van-cell title="绑定手机号码：" icon="phone-o" :value="employeeInfo.mobile" />
        <van-cell title="绑定邮箱：" icon="envelop-o" :value="employeeInfo.email" />
        <van-cell title="所属机构：" icon="wap-home-o" :value="employeeInfo.defaultOrganizationName" />
        <van-cell title="所属分支机构：" icon="cluster-o" :value="employeeInfo.defaultRoleName" />
        <van-cell title="角色权限：" icon="friends-o" :value="roleNameList.join(',')" />
        <!-- <p class="personal-down-info">
          <span>所属机构：</span>
          {{ employeeInfo.defaultOrganizationName }}
        </p>
        <p class="personal-down-info">
          <span>所属分支机构：</span>
          {{ employeeInfo.defaultRoleName }}
        </p>
        <p class="personal-down-info">
          <span>角色权限：</span>
          {{ roleNameList.join(',') }}
        </p> -->
      </div>
    </div>

    <van-button class="log-out" type="info" color="#1373CC" block text="退出登录" @click="logOut" />
    <tab-bar active="personal" />
  </div>
</template>

<script>
import TabBar from '@/components/tab-bar'
import { Toast, Button, Cell, Icon } from 'vant'
export default {
  components: {
    [Cell.name]: Cell,
    [Button.name]: Button,
    [Icon.name]: Icon,
    TabBar
  },
  data () {
    return {
      employeeInfo: {},
      roleNameList: [],
      enterpriseInfo: {}
    }
  },
  created () {
    this.getEmployeeInfo().then(result => {
      this.employeeInfo = result
      console.log(this.employeeInfo)
      this.listRoleByUserId()
    })
  },
  methods: {
    getEmployeeInfo () {
      return new Promise((resolve, reject) => {
        if (this.$store.state.employeeInfo.orgType) {
          resolve(this.$store.state.employeeInfo)
        } else {
          this.api.base.myInfo().then(result => {
            this.$store.commit('updateEmployeeInfo', result.data.value)
            resolve(result.data.value)
          })
        }
      })
    },
    listRoleByUserId () {
      const params = {
        userId: this.employeeInfo.userId
      }
      this.api.base.listRoleByUserId(params).then(result => {
        if (result.data.value && result.data.value.length > 0) {
          result.data.value.forEach(item => {
            this.roleNameList.push(item.roleName)
          })
        } else {
          this.api.base.quota(this.$store.state.employeeInfo.enterpriseId).then(res => {
            this.enterpriseInfo = res.data.value
          })
        }
      })
    },
    logOut () {
      this.api.base.logOut().then(result => {
        Toast.success('退出成功')
        this.$store.commit('removeEmpInfo')
        window.location.href = process.env.VUE_APP_CONSOLE_DOMAIN + '/login'
      })
    }
  }
}
</script>

<style lang="less" scoped>
  .personal {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
    .platform{
      .personal-top,.personal-down {
        padding: 0 10% 20px;
        margin: 20px auto;
        font-weight: bold;
      }
      .personal-top {
        .personal-top-userName{
          font-size: 0.58667rem !important;
          font-weight: bold;
          margin: 2.13333rem 0 1.06667rem;
        }
        .personal-top-mobile,.personal-top-email{
          margin-top: 10px;
          font-size: 18px !important;
        }
      }
      .van-common-detail{
        .van-cell__title{
          flex:0.4;
        }
        .van-cell__value{
          flex: 0.6;
          text-align: left;
        }
        // .personal-down-info{
        //   font-size: 0.37333rem !important;
        //   margin-top: 0.26667rem;
        //   font-weight: normal;
        //   span{
        //     font-weight: bold;
        //   }
        // }
      }
    }
    .enterprise{
      .van-common-detail{
        .van-cell__title{
            font-weight: bold;
            font-size: 16px;
          }
          .van-cell__value{
            font-size: 16px;
          }
      }
      .contract-item{
        border:1px solid #1989fa;
        margin: 10px;
        border-radius: 4px;
        .bigtxt{
          margin-top: 10px;
          .van-cell__title{
            font-size: 16px;
            font-weight: bold;
          }
        }
      }
    }
  }

  .log-out{
    width: 30% !important;
    margin: 20px auto 40px;
    padding: 0px 10px !important;
  }
</style>
