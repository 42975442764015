var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "personal" },
    [
      _vm.$store.state.employeeInfo.userType === 4
        ? _c("div", { staticClass: "enterprise" }, [
            _c(
              "div",
              { staticClass: "van-common-detail" },
              [
                _c("van-cell", {
                  attrs: {
                    title: "企业名称：",
                    icon: "newspaper-o",
                    value: _vm.employeeInfo.defaultOrganizationName
                  }
                }),
                _c("van-cell", {
                  attrs: {
                    title: "企业管理员：",
                    icon: "user-o",
                    value: _vm.employeeInfo.realName
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "contract-item" },
              [
                _c("van-cell", {
                  staticClass: "bigtxt",
                  attrs: { title: "我的额度", icon: "after-sale" }
                }),
                _c("van-cell", {
                  attrs: {
                    title: "产品类型：",
                    value: _vm.utils.statusFormat(
                      String(_vm.enterpriseInfo.productType),
                      "ProjectProductType"
                    )
                  }
                }),
                _c("van-cell", {
                  attrs: {
                    title: "合作额度(万元)：",
                    value: _vm.utils.moneyFormat(_vm.enterpriseInfo.quota)
                  }
                }),
                _c("van-cell", {
                  attrs: {
                    title: "合作期限(月)：",
                    value: _vm.utils.isEffectiveCommon(_vm.enterpriseInfo.term)
                  }
                }),
                _vm.enterpriseInfo.quotaDateStart &&
                _vm.enterpriseInfo.quotaDateEnd
                  ? _c("van-cell", {
                      attrs: {
                        title: "额度起止日：",
                        value:
                          _vm.utils.dateFormat(
                            _vm.enterpriseInfo.quotaDateStart
                          ) +
                          "至" +
                          _vm.utils.dateFormat(_vm.enterpriseInfo.quotaDateEnd)
                      }
                    })
                  : _c("van-cell", {
                      attrs: { title: "额度起止日：", value: "-" }
                    })
              ],
              1
            )
          ])
        : _c("div", { staticClass: "platform" }, [
            _c(
              "div",
              { staticClass: "van-common-detail" },
              [
                _c("van-cell", {
                  attrs: {
                    title: "用户名：",
                    icon: "user-circle-o",
                    value: _vm.employeeInfo.userName
                  }
                }),
                _c("van-cell", {
                  attrs: {
                    title: "绑定手机号码：",
                    icon: "phone-o",
                    value: _vm.employeeInfo.mobile
                  }
                }),
                _c("van-cell", {
                  attrs: {
                    title: "绑定邮箱：",
                    icon: "envelop-o",
                    value: _vm.employeeInfo.email
                  }
                }),
                _c("van-cell", {
                  attrs: {
                    title: "所属机构：",
                    icon: "wap-home-o",
                    value: _vm.employeeInfo.defaultOrganizationName
                  }
                }),
                _c("van-cell", {
                  attrs: {
                    title: "所属分支机构：",
                    icon: "cluster-o",
                    value: _vm.employeeInfo.defaultRoleName
                  }
                }),
                _c("van-cell", {
                  attrs: {
                    title: "角色权限：",
                    icon: "friends-o",
                    value: _vm.roleNameList.join(",")
                  }
                })
              ],
              1
            )
          ]),
      _c("van-button", {
        staticClass: "log-out",
        attrs: { type: "info", color: "#1373CC", block: "", text: "退出登录" },
        on: { click: _vm.logOut }
      }),
      _c("tab-bar", { attrs: { active: "personal" } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }